import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import { PricingWrapper } from './pricing.style';
import PricingHeadImage from 'common/src/assets/image/crypto/Pricing.svg';
import Example1 from 'common/src/assets/image/crypto/Example01.svg';
import Example2 from 'common/src/assets/image/crypto/Example02.svg';
import Example3 from 'common/src/assets/image/crypto/Example03.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const Pricing = ({
  row,
  col,
  title,
  description,
  exampleDescription,
  cancellationDescription,
  excludeVAT,
  cardArea,
}) => {
  return (
    <PricingWrapper id="pricing">
      <Container>
        <Box>
          <Box className="row" {...row}>
            <Box
              className="colleft"
              {...col}
              style={{ flexDirection: 'column' }}
            >
              <Heading content="" {...title} />

              <Box>
                <Container className="pricingBody">
                  We believe in simplicity. One pricing option for all our
                  clients and the best incentive for a software provider-client
                  relationship:
                  <br />{' '}
                  <b>
                    No flat subscription fee, we are aligned in terms of
                    incentives to the client.
                  </b>
                  <br />
                  Our pricing is based on the hypothetical profit&loss generated
                  with our software, our fee is 25% of your hypothetical profit
                  (including VAT). We measure the profit at the end of each
                  calendar month subject to high-water mark, which means that at
                  end of the month we charge only if your account reached the
                  highest peak in value since you sign for the service. We
                  measure profit making sure we don’t consider for the fee
                  calculation additional deposits and withdraws since you sign
                  for our service.
                </Container>
              </Box>
            </Box>

            <Box className="colright" {...col} {...cardArea}>
              <Image
                src={PricingHeadImage}
                className="ScalableImage"
                alt="Scalable Section Image"
              />
            </Box>
          </Box>

          <Container>
            <Text content="" {...exampleDescription} />
            <Carousel
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              autoPlay={true}
              interval={5000}
              stopOnHover={true}
              infiniteLoop={true}
              transitionTime={600}
            >
              <div>
                <Box className="row" {...row}>
                  <Box
                    className="colleft"
                    {...col}
                    style={{ flexDirection: 'column' }}
                  >
                    <Image
                      src={Example1}
                      className="controlImage"
                      alt="Control Section Image"
                    />
                  </Box>
                  <Box className="colright" {...col} {...cardArea}>
                    <FeatureBlock
                      className="subtitle"
                      title={<Heading content="Example 1" />}
                      description={
                        <Text
                          content="At the end of the first month the account earns $500, we will kindly ask you to pay the 25% of $500 through our payment services. At the end of the second month the account earns $500, we will kindly ask you to pay the 25% of $500 through our payment services."
                          {...description}
                        />
                      }
                    />
                  </Box>
                </Box>
              </div>

              <div>
                <Box className="row" {...row}>
                  <Box
                    className="colleft"
                    {...col}
                    style={{ flexDirection: 'column' }}
                  >
                    <Image
                      src={Example2}
                      className="controlImage"
                      alt="Control Section Image"
                    />
                  </Box>
                  <Box className="colright" {...col} {...cardArea}>
                    <FeatureBlock
                      className="subtitle"
                      title={<Heading content="Example 2" />}
                      description={
                        <Text
                          content="At the end of the first month the account loses $500, you will not pay for our service. At the end of the second month the account earns $400 you will not pay for our service because of the high-water mark, only when your account recover the $500 loss we will start charge for our software licence."
                          {...description}
                        />
                      }
                    />
                  </Box>
                </Box>
              </div>

              <div>
                <Box className="row" {...row}>
                  <Box
                    className="colleft"
                    {...col}
                    style={{ flexDirection: 'column' }}
                  >
                    <Image
                      src={Example3}
                      className="controlImage"
                      alt="Control Section Image"
                    />
                  </Box>
                  <Box className="colright" {...col} {...cardArea}>
                    <FeatureBlock
                      className="subtitle"
                      title={<Heading content="Example 3" />}
                      description={
                        <Text
                          content="At the end of the first month the account earns $500, we will kindly ask you to pay the 25% of $500 through our payment services. At the beginning of the second month you deposit additional $10000 in your account. At the end of the second month the account earns $2000, we will kindly ask you to pay the 25% of $2000 through our payment services."
                          {...description}
                        />
                      }
                    />
                  </Box>
                </Box>
              </div>
            </Carousel>

            <Text content="" {...cancellationDescription} />
          </Container>
        </Box>
      </Container>
    </PricingWrapper>
  );
};

// Transactions style props
Pricing.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  readMoreTitle: PropTypes.object,
  btnStyle: PropTypes.object,
};

// Transactions default style
Pricing.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Transactions section title default style
  title: {
    content: 'Pricing',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },
  // Transactions section description default style
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '35px', '35px', '45px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  exampleDescription: {
    content:
      'For example, let’s assume you sign for our service and you deposit $10,000 on one of the supported exchanges at the beginning of a month. We use few examples to explain our pricing:',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '35px', '35px', '45px'],
    maxWidth: ['100%', '100%', '100%', '100%', '100%'],
  },
  cancellationDescription: {
    content:
      'If you don’t pay after two weeks from our payment request, we cancel the licence. You can cancel the licence anytime.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    maxWidth: ['100%', '100%', '100%', '100%', '100%'],
  },
  excludeVAT: {
    content: '*excluding VAT',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
  },
  sectionSubTitle: {
    as: 'span',
    textAlign: 'left',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
  },
};

export default Pricing;
