import styled from 'styled-components';

const PricingWrapper = styled.section`
  margin-bottom: 40px;
  // z-index: 1;
  padding-top: 80px;

  .pricingContainerClass {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 94px 6px rgba(107, 83, 254, 0.1);
    padding: 80px 0;
    margin-bottom: 40px;
  }

  .row {
    margin-bottom: 60px;
  }

  .carousel .control-dots .dot {
    box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.9) !important;
  }

  .carousel .slide {
    background: transparent;
  }

  .pricingBody {
    box-sizing: border-box;
    margin-bottom: 30px;
    margin-top: 0px;
    padding: 20px;
    max-width: 100%;
    font-size: 16px;
    color: #525f7f;
    font-weight: 400;
    line-height: 28px;
  }

  .subtitle {
    box-sizing: border-box;
    margin-bottom: 1rem;
    margin-top: 0px;
    font-size: 16px;
    color: #525f7f;
    font-family: Poppins;
    font-weight: 600;
    text-align: left;
    line-height: 27px;
  }

  @media (max-width: 1440px) {
    padding: 0;
  }
`;

export { PricingWrapper };
